import axios from 'axios';
import moment from 'moment';
import Loader from '@/components/Loader/Loader.vue';
import Highcharts from 'highcharts';
export default {
    name: 'FacebookCampaignSummary',
    components: {
        Loader,
    },
    data() {
        return {
            dateRange: {
                startDate: moment().subtract(1, 'year').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            loader: false,
            campaignTypeList: [],
            selectedCampaignType: "",
            campaignNamesList: [
                {
                    campaign_name: "All Campaigns",
                    id: "all"
                }
            ],
            selectedCampaignName: "",
            adsetsList: ["All Adsets"],
            selectedAdset: "",
            adNameList: ["All Ads"],
            selectedAdName: "",
            metricDropdown: 'CTR',
            breakdownsList: ['Region', 'Country', 'Age', 'Gender', 'Platform', 'Placement', 'Hour'],
            selectedBreakdown: "Region",
            boxBulkData: [],
            boxBulkHeader: [],
            metricsArr: [],
            selectedMetric: "",
            timelineTableHeader: ["Date"],
            timelineTabledata: [],
            timelineChart: {
                timelineXAxis: [],
                timelineSeriesData: [],
                plotChartData: {}
            },
            breakdownTableHeader: ["Breakdown"],
            selectedBreakdownChartDrop: "",
            breakdownTabledata: [],
            breakdownChart: {
                breakdownXAxis: [],
                breakdownSeriesData: [],
                plotChartData: {}
            },
            campBulkTableTitle: "",
            campBulkTableHeader: [],
            campBulkTabledata: [],
        }
    },
    mounted() {
        this.getCampaignTypes()
    },
    methods: {
        getCampaignTypes() {
            this.loader = true;
            const url = "/api/fb/all-campaign-types-in-account";
            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
            }
            axios.get(url, { params: payload }).then(res => {
                this.campaignTypeList = res.data.data;
                this.selectedCampaignType = this.campaignTypeList[0];
                this.getCampaignNames();
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        changeCampType() {
            this.campaignNamesList = [
                {
                    campaign_name: "All Campaigns",
                    id: "all"
                }
            ];
            this.selectedCampaignName = this.campaignNamesList[0];
            this.adsetsList = ["All Adsets"];
            this.selectedAdset = this.adsetsList[0];
            this.adNameList = ["All Ads"];
            this.selectedAdName = this.adNameList[0];
            this.getCampaignNames();
            this.getBoxData();
            this.getTimelineData();
            // this.getBreakdownData();
            this.getCampBulkTableData();
        },
        getCampaignNames() {
            this.loader = true;
            const url = "/api/fb/all-campaigns-in-campaign-type";
            this.campaignNamesList = [
                {
                    campaign_name: "All Campaigns",
                    id: "all"
                }
            ]
            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                camp_type: this.selectedCampaignType,
            };
            axios.get(url, { params: payload }).then(res => {
                for (let campaigns of res.data.data) {
                    this.campaignNamesList.push(campaigns)
                }
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        changeCampName() {
            this.adsetsList = ["All Adsets"];
            this.selectedAdset = this.adsetsList[0];
            this.adNameList = ["All Ads"];
            this.selectedAdName = this.adNameList[0];
            this.getAdsets();
            this.getBoxData();
            this.getTimelineData();
            // this.getBreakdownData();
            this.getCampBulkTableData();
        },
        getAdsets() {
            this.loader = true;
            const url = "/api/fb/campaign/adsets/list";
            let payload = {
                campaign_id: this.selectedCampaignName.id,
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }
            this.adsetsList = ["All Adsets"]
            axios.get(url, { params: payload }).then(res => {
                let resData = res.data.data;
                for (let adset of resData) {
                    this.adsetsList.push(adset)
                }
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        changeAdset() {
            this.adNameList = ["All Ads"];
            this.selectedAdName = this.adNameList[0];
            this.getBoxData();
            this.getTimelineData();
            this.getAds();
            console.log("this hit")
            console.log(this.selectedCampaignName.id)
        },
        getAds() {
            this.loader = true;
            const url = "/api/fb/adset/ads/list";
            let payload = {
                campaign_id: this.selectedCampaignName.id,
                adset_name: this.selectedAdset,
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }
            if(this.selectedAdset === "All Adsets") payload["adset_name"] = "all"
            axios.get(url, { params: payload }).then(res => {
                for (let ads of res.data.data) {
                    this.adNameList.push(ads)
                }
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        getBoxData() {
            this.loader = true;
            let url, payload;
            this.boxBulkData = [];
            this.boxBulkHeader = [];
            if (this.selectedAdset === "All Adsets") {
                url = "/api/fb/bulk/camp_types";
                payload = {
                    camp_type: this.selectedCampaignType,
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                }
                if (this.selectedCampaignName.id !== 'all') {
                    url = "/api/fb/bulk/camp";
                    Object.assign(payload, { camp_id: this.selectedCampaignName.id })
                }
            } else if (this.selectedAdset !== "All Adsets") {
                url = "/api/fb/adset/single_bulk";
                payload = {
                    campaign_id: this.selectedCampaignName.id,
                    adset_name: encodeURIComponent(this.selectedAdset),
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
                };
                if (this.selectedAdName !== "All Ads") {
                    url = "/api/fb/ad/single_bulk"
                    Object.assign(payload, { ad_name: encodeURIComponent(this.selectedAdName) })
                }
            }
            axios.get(url, { params: payload }).then(res => {
                this.boxBulkData = res.data
                for (let header in this.boxBulkData.data) {
                    this.boxBulkHeader.push(header)
                }
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        getTimelineData() {
            this.loader = true;
            let url, payload;
            if (this.selectedAdset === "All Adsets") {
                url = "/api/fb/timeline/camp_types";
                payload = {
                    camp_type: this.selectedCampaignType,
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    timeline: "day"
                }
                if (this.selectedCampaignName.id !== 'all') {
                    url = "/api/fb/timeline/camp";
                    delete payload["timeline"]
                    Object.assign(payload, { camp_id: this.selectedCampaignName.id })
                }
            } else if (this.selectedAdset !== "All Adsets") {
                url = "/api/fb/adset/timeline";
                payload = {
                    adset_name: encodeURIComponent(this.selectedAdset),
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    timeline: "day"
                };
                if (this.selectedAdName !== "All Ads") {
                    url = "/api/fb/ad/timeline";
                    Object.assign(payload, { ad_name: encodeURIComponent(this.selectedAdName) }, { campaign_id: this.selectedCampaignName.id })
                }
            }
            $("#timelineTable").DataTable().destroy();
            this.timelineTableHeader = ["Date"];
            this.timelineTabledata = [];
            this.timelineChart = {
                timelineXAxis: [],
                timelineSeriesData: [],
                plotChartData: {}
            };
            this.metricsArr = [];
            axios.get(url, { params: payload }).then(res => {
                let resData = res.data.data;
                for (let timelineKey of Object.keys(resData[this.selectedCampaignType][0].data)) {
                    this.metricsArr.push(timelineKey);
                    this.selectedMetric = this.metricsArr[0];
                    this.timelineTableHeader.push(timelineKey);
                }
                this.timelineTabledata = resData[this.selectedCampaignType]
                this.processTimelineChart();
                this.$nextTick(() => {
                    this.processTimelineTable()
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        processTimelineChart() {
            this.timelineChart.timelineXAxis = [];
            this.timelineChart.timelineSeriesData = [];
            this.timelineTabledata.map(dataset => {
                this.timelineChart.timelineXAxis.push(dataset.date);
                this.timelineChart.timelineSeriesData.push(Number(dataset.data[this.selectedMetric].toFixed(2)))
            })
            Highcharts.setOptions({
                lang: {
                    decimalPoint: '.',
                    thousandsSep: ','
                }
            });
            this.timelineChart.plotChartData = {
                chart: {
                    type: 'line',
                    backgroundColor: 'transparent'
                },
                title: {
                    text: '',
                },
                subtitle: {
                    text: '',
                },
                credits: {
                    enabled: false
                },
                yAxis: {
                    title: {
                        text: this.selectedMetric
                    }
                },
                xAxis: [{
                    categories: this.timelineChart.timelineXAxis,
                    crosshair: true,
                    tickWidth: 2
                }],
                tooltip: {
                    formatter: function () {
                        return this.x + ' : <b>' + (this.y).toLocaleString("en-US") + '</b>';
                    },
                    borderWidth: 0,
                    shadow: {
                        color: "#d6d6d6",
                        opacity: 0.1,
                        width: 10,
                    },
                    borderRadius: 6,
                    backgroundColor: "#ffffff",
                    headerFormat: '',
                    style: {
                        fontSize: '14px'
                    },
                },
                legend: {
                    itemStyle: {
                        fontSize: "0.8vw"
                    },
                    enabled: false,
                    alignColumns: true,
                    align: "right",
                    y: 0,
                    x: 0,
                    squareSymbol: true,
                    verticalAlign: "top",
                    itemDistance: 35,
                    margin: 0,
                    padding: 0,
                    symbolHeight: 14,
                    symbolWidth: 14,
                    symbolRadius: 4
                },
                series: [{
                    data: this.timelineChart.timelineSeriesData,
                    name: this.selectedMetric,
                    color: '#47A694'
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'top'
                            },
                            chart: {
                                style: {
                                    fontSize: '0.5rem'
                                }
                            }
                        }
                    }]
                }
            }
        },
        processTimelineTable() {
            let tableID = `#timelineTable`;
            if (this.timelineTabledata.length > 10) {
                var search = require("@/assets/Icons_SVG/Search.svg");
                $(tableID).dataTable({
                    searching: true,
                    info: false,
                    lengthChange: true,
                    sorting: [[1, "desc"]],
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    language: {
                        search: "", searchPlaceholder: "Search...", paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",
                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_"
                    },
                })
                $(tableID + "_filter > label").append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
            } else {
                $(tableID).dataTable({
                    searching: false,
                    info: false,
                    lengthChange: false,
                    sorting: [[0, "desc"]],
                    paginate: false,
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                })
            }
        },
        getBreakdownData() {
            this.loader = true;
            let url, payload;
            if (this.selectedAdset === "All Adsets") {
                url = "/api/fb/breakdown/camp_types";
                payload = {
                    camp_type: this.selectedCampaignType,
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    breakdown: this.selectedBreakdown.toLowerCase()
                }
                if (this.selectedCampaignName.id !== 'all') {
                    url = "/api/fb/breakdown/camp";
                    Object.assign(payload, { camp_id: this.selectedCampaignName.id })
                }
            }
            
            this.breakdownChart.breakdownXAxis = [];
            this.breakdownTableHeader = ["Breakdown"];
            this.breakdownTabledata = [];
            this.breakdownChart.breakdownSeriesData = [];
            axios.get(url, { params: payload }).then(res => {
                let resData = res.data.data

                for (let headers of resData) {
                    //data breakdown chart xAxis
                    for (let keys of Object.keys(headers)) {
                        this.breakdownChart.breakdownXAxis.push(keys)
                    }
                    //breakdown table data
                    for (let item of Object.values(headers)) {
                        this.breakdownTabledata.push(item)
                    }
                }
                // data breakdown table header
                for (let item of Object.entries(resData[0])) {
                    for (let tableHeads of Object.keys(item[1])) {
                        this.breakdownTableHeader.push(tableHeads)
                    }
                }
                this.selectedBreakdownChartDrop = this.breakdownTableHeader[1]
                this.processBreakdownChart();
                this.$nextTick(() => {
                    this.processBreakdownTable()
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        processBreakdownChart() {
            this.breakdownChart.breakdownSeriesData = [];
            console.log("chart data", this.breakdownChart.breakdownSeriesData)
            this.breakdownTabledata.map(dataset => {
                this.breakdownChart.breakdownSeriesData.push(Number(dataset[this.selectedBreakdownChartDrop].toFixed(2)))
            })
            Highcharts.setOptions({
                lang: {
                    decimalPoint: '.',
                    thousandsSep: ','
                }
            });
            this.breakdownChart.plotChartData = {
                chart: {
                    type: 'line',
                    backgroundColor: 'transparent'
                },
                title: {
                    text: '',
                },
                subtitle: {
                    text: '',
                },
                credits: {
                    enabled: false
                },
                yAxis: {
                    title: {
                        text: this.selectedBreakdownChartDrop
                    }
                },
                xAxis: [{
                    categories: this.breakdownChart.breakdownXAxis,
                    crosshair: true,
                    tickWidth: 2
                }],
                tooltip: {
                    formatter: function () {
                        return this.x + ' : <b>' + (this.y).toLocaleString("en-US") + '</b>';
                    },
                    borderWidth: 0,
                    shadow: {
                        color: "#d6d6d6",
                        opacity: 0.1,
                        width: 10,
                    },
                    borderRadius: 6,
                    backgroundColor: "#ffffff",
                    headerFormat: '',
                    style: {
                        fontSize: '14px'
                    },
                },
                legend: {
                    itemStyle: {
                        fontSize: "0.8vw"
                    },
                    enabled: false,
                    alignColumns: true,
                    align: "right",
                    y: 0,
                    x: 0,
                    squareSymbol: true,
                    verticalAlign: "top",
                    itemDistance: 35,
                    margin: 0,
                    padding: 0,
                    symbolHeight: 14,
                    symbolWidth: 14,
                    symbolRadius: 4
                },
                series: [{
                    data: this.breakdownChart.breakdownSeriesData,
                    name: this.selectedBreakdownChartDrop,
                    color: '#47A694'
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'top'
                            },
                            chart: {
                                style: {
                                    fontSize: '0.5rem'
                                }
                            }
                        }
                    }]
                }
            }
        },
        processBreakdownTable() {
            let tableID = `#breakdownTable`;
            if (this.breakdownTabledata.length > 10) {
                var search = require("@/assets/Icons_SVG/Search.svg");
                $(tableID).dataTable({
                    searching: true,
                    info: false,
                    paginate: true,
                    lengthChange: true,
                    sorting: [[1, "desc"]],
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    language: {
                        search: "", searchPlaceholder: "Search...", paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",
                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_"
                    },
                })
                $(tableID + "_filter > label").append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
            } else {
                $(tableID).dataTable({
                    searching: false,
                    info: false,
                    lengthChange: false,
                    sorting: [[0, "desc"]],
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    paginate: false,
                    
                })
            }
        },
        getCampBulkTableData() {
            this.loader = true;
            let url, payload;
            if (this.selectedCampaignName.id === 'all') {
                url = "api/fb/bulk/camp_wise";
                payload = {
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    camp_type: this.selectedCampaignType
                }
                this.campBulkTableTitle = "Campaigns";
            } else {
                if (this.selectedAdset === 'All Adsets') {
                    url = "/api/fb/campaign/adsets_bulk";
                    payload = {
                        camapign_id: this.selectedCampaignName.id,
                        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    };
                    this.campBulkTableTitle = "Adsets";
                } else {
                    if (this.selectedAdName == 'All Ads') {
                        url = "/api/fb/adset/ad_bulk";
                        payload = {
                            camapign_id: this.selectedCampaignName.id,
                            start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                            end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                            adset_name: encodeURIComponent(this.selectedAdset)
                        };
                        this.campBulkTableTitle = "Ads";
                    } else return;
                }
            }
            $("#campBulkTable").DataTable().destroy();
            this.campBulkTableHeader = [];
            this.campBulkTabledata = [];
            axios.get(url, {params: payload}).then(res => {
                this.campBulkTableHeader = res.data.header;
                this.campBulkTabledata = res.data.data;
                this.loader = false;
                this.$nextTick(() => {
                    this.processCampBulkTable();
                })
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        processCampBulkTable(){
            let tableID = `#campBulkTable`;
            if (this.campBulkTabledata.length > 10) {
                var search = require("@/assets/Icons_SVG/Search.svg");
                $(tableID).dataTable({
                    searching: true,
                    info: false,
                    paginate: true,
                    lengthChange: true,
                    sorting: [[0, "desc"]],
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    language: {
                        search: "", searchPlaceholder: "Search...", paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",
                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_"
                    },
                })
                $(tableID + "_filter > label").append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
            } else {
                $(tableID).dataTable({
                    searching: false,
                    info: false,
                    lengthChange: false,
                    sorting: [[0, "desc"]],
                    autoWidth: false,
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    paginate: false,
                })
            }
        },

        // Generic function
        formatNumber(num) {
            if (isNaN(num)) {
                return num
            }
            const num1 = Number(num).toFixed(2);
            const num2 = Number(num1).toLocaleString();
            return num2;
        }


    }
}
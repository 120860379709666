import { render, staticRenderFns } from "./AMS.vue?vue&type=template&id=0632f533&scoped=true&lang=pug"
import script from "./AMS.js?vue&type=script&lang=js&external"
export * from "./AMS.js?vue&type=script&lang=js&external"
import style0 from "./AMS.vue?vue&type=style&index=0&id=0632f533&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0632f533",
  null
  
)

export default component.exports